import DOMPurify from 'isomorphic-dompurify';

export const removeHtml = (str, mode) => {
  if (typeof(str) == 'undefined') {
    return '';
  }

  if (process.server) {
    /*
    Due to the issue mentioned below for DPD-204, when this function is called from the server side, change the mode to 'none'
    */
    if (mode != 'strict') {
      mode = 'none';
    }
  }

  if (typeof(str) === "string") {
    if (mode == 'none') {
      return str;
    }
    //return str;
    if (mode == 'strict') {
      //return str.replace(/(<([^>]+)>)/gi, "").trim();
    }

    /*
     We are using: https://www.npmjs.com/package/sanitize-html
    */
    /*
    For DPD-204, we needed to add the class attribute to the allowed list for all tags, but when we calls 
    sanitizeHtml.defaults.allowedAttributes.concat(['class']) it throws an error.  It looks like 
    sanitizeHtml.defaults.allowedAttributes is a dictionary, not an array.  So the documentation is not 
    correct.  For now, I am going to call this function with mode of 'none', to bypass this issue for now.
    This is not ideal, but we will come back to it later.  We will need to search the code to see where this 
    function is being invoked with mode='none' and remove the mode parameter.  If the mode parameter is not 
    specified (not 'none' or 'strict'), it will be treated as limited / partial, and use the sanitizeHtml function below.

    The sanitize-html package is bloated and contains Asian characters probably from other packages that it depends on.
    So, we are no longer using the sanitize-html package.  This function, if mode is not specified, or if specified but 
    other than ('none', or 'strict'), it will be treated as strict.  For places that we want to preserve HTML formatting, 
    do not use this function.  I am assuming that Quill and code mirror can handle HTML and that there is no security 
    issue there even though the HTML had been tempered.  

    The description, by default, contains paragraph elements and the < and > characters by default get escaped when we use 
    the {{ }} construct.  If we want to prevent that, we can use this function.

    let result = sanitizeHtml(str);
    */
    
    //let result = str.replace(/(<([^>]+)>)/gi, "").trim();
    const result = DOMPurify.sanitize(str);
    return result;
  } else if (typeof(str) == 'undefined') {
    return ''
  } else if (['number','boolean','bigint','symbol'].includes(typeof str)) {
    return str + '';
  } else {
    return ''
  }
};
